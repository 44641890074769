import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pin-a-tab"
    }}>{`Pin a Tab`}</h1>
    <hr></hr>
    <p>{`To pin a tab at the top of your app for your patrons, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Click on the Mobile App where you want to pin the tab. `}</li>
      <li parentName="ol">{`Select the tab you wish to pin from the Tabs Manager. `}</li>
      <li parentName="ol">{`Select 'Edit Tab: (tab name). This action will load the Edit Tab page. `}</li>
      <li parentName="ol">{`Toggle Pinned Notification. This will override the patron's preferences within the app and will always have this tab remain at the top of the list. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to save the changes. `}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app. `}</li>
    </ol>
    <p><img alt="CMS Pin Tab" src={require("./images/cms_pin_tab.gif")} />{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      